@import '~antd/dist/antd.less';
@import '~antd/dist/antd.dark.less';

@import url('https://fonts.googleapis.com/css2?family=Unica+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap:wght@700&family=Roboto:wght@300&display=swap');

.image_container
{
        perspective: 800px;
        -moz-perspective: 800px;
        -ms-perspective: 800px;
        -o-perspective: 800px;
        -webkit-perspective:800px;

        transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
}
.image_container img {
       transform: rotate3d(0,1,0,-20deg);
       -moz-transform: rotate3d(0,1,0,-20deg);
       -ms-transform: rotate3d(0,1,0,-20deg);
       -o-transform: rotate3d(0,1,0,-20deg);
       -webkit-transform: rotate3d(0,1,0,-20deg);
}

.image_container img.game {
  max-width: 80%;

}
.image_container img.text {
  left: 8%;
  max-width: 40%;
}

.content{
  padding-top: 0px;
}

@media only screen and (max-width: 767px) {
  // .content{
  //   padding-top: 150px !important;
  // }
  .image_container{
    display:block;
    // background:black;
  }
  .image_container img {
    transform: rotate3d(0,1,0,0deg);
    -moz-transform: rotate3d(0,1,0,0deg);
    -ms-transform: rotate3d(0,1,0,0deg);
    -o-transform: rotate3d(0,1,0,0deg);
    -webkit-transform: rotate3d(0,1,0,0deg);
  }
  .image_container img.game{
    max-width: 100%;
    margin-bottom:100px;
  }
  .image_container img.text {
    left: 2%;
    max-width: 50%;
  }
}

.mainButton {
  transition:.2s all ease;
    opacity:.5;
    text-shadow: 2px 2px rgba(0,0,0,.3);
    font-size: 42px;
    line-height: 1em;
    height:auto !important;
    letter-spacing: 4px;
    &:hover{
        opacity:1;
    }
    small {
        letter-spacing: 4px;
        font-size:.2em;
        line-height:1em;
        display:block;
    }
}

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    // background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

html{ background-color: #14072a; }

body {
  font-family:'Roboto';
  margin:0;
  background: #14072a;
  position:relative;
  min-height:100vh;
}


.flexContainer:after{
  opacity:.4;
  content:'';
  z-index:0;
  position:absolute;
  bottom:0;
  left:50%;
  background:red;
  width:75%;
  height:300px;
  min-width:1000px;
  transform:translateX(-50%);
  background: radial-gradient(at bottom, #d75e7f, transparent, transparent);
}

.flexContainer:before{
  opacity:.6;
  content:'';
  z-index:0;
  position:absolute;
  top:0;
  left:50%;
  background:red;
  width:75%;
  height:300px;
  min-width:1000px;
  transform:translateX(-50%);
  background: radial-gradient(at top, #006879, transparent, transparent);
}

.flexContainer{
  background:#14072a;
  display:flex;
  flex-direction:column;
  min-height:100vh;
}

.logo small{
  font-size:.5em;
  position:absolute;
  opacity:.8;
  color:gold;
}
.logo{
  display:inline-block;
  z-index:1;
  text-decoration:none;
  font-family:'Asap';
  color:#fff;
  font-size:2em;
}

.logoContainer {
  justify-content:center;
  display:flex;
  padding:20px;
}

.content{
  z-index:1;
  padding: 10px 20px;
  justify-content:center;
  color:#fff;
  align-items:center;
  display:flex;
  flex:1;
  flex-direction:column;
}

.content p {
  max-width:600px;
  font-size:5em;
  line-height:1em;
}

.content ul{
  opacity:.7;
  margin-left:0;
  // font-weight:bold;
  font-size:5vh;
  line-height:7vh;
}

.content button{
  color:#14072a;
  margin-top:30px;
  padding:10px 50px;
  border-radius:20px;
  background:lightblue;
  outline:none;
  font-family:'Asap';
  border:none;
  transition:.3s all ease;
}

.content button:hover{
  cursor:pointer;
  background:#d75e7f;
  color:#fff;
}

.content p{
  text-align:center;
  margin:0;
}


.ant-modal{
  top:0 !important;
}


@media only screen and (max-width: 767px) {
  .content p{
    font-size:3em;
    line-height:1em;
  }
  .flexContainer:before{
    width:100%;
    height:200px;
    min-width:auto;
    background: radial-gradient(at top, #006879, transparent, transparent);
  }
  .flexContainer:after{
    width:100%;
    min-width:auto;
    height:200px;
    background: radial-gradient(at bottom, #d75e7f, transparent, transparent);
  }
}
@primary-color: #4a90e2;@dark: true;